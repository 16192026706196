@mixin fontFace($family, $src, $style: normal, $weight: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$src}.woff2') format('woff2'), // standards
			url('#{$src}.ttf') format('truetype'); // Safari, Android, iOS

		font-style: $style;
		font-weight: $weight;
		font-size: 12px;
	}
}