.rating
  width: 0.75rem
  height: 0.75rem
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  margin-right: 0.5rem

  &:last-child
    margin-right: 0

  &.rating-filled
    background-color: rgba($warning, 0.75)
    text-shadow: rgba($warning, 0.75) 0 0 2px
  &.rating-half-filled
    background-color: rgba($warning, 0.25)
    text-shadow: rgba($warning, 0.25) 0 0 2px
