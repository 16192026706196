@keyframes spinning
  0%
    transform: rotate(0)
  50%
    transform: rotate(180deg)
  100%
    transform: rotate(360deg)

.spin
  animation: 2s infinite spinning
