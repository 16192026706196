.menu_planner
  &__week
    margin-left: -0.5rem
    margin-right: -0.5rem
  &__day
    width: 20%

    @media screen and ( max-width: 1200px )
      font-size: $font-size-sm

    @media screen and ( max-width: 800px )
      .timeslot__dishes
        .avatar.avatar-lg
          width: 4rem
          height: 4rem
          min-width: 4rem
          min-height: 4rem
