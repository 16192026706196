.timeslot
  margin-bottom: 2rem

  &__row
    min-height: 420px
    display: flex
    flex-wrap: wrap

  &__dishes
    position: relative

  &__heading
    background-color: #F2F2F2
    padding: 0.25rem 2rem
    margin-left: -0.5rem
    margin-right: -0.5rem

  &__add
    color: $dark
    text-decoration: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-color: $gray-300
    border-radius: $border-radius-lg
    height: 100%

    transition: background-color 0.25s
    &:hover
      background-color: $gray-200

  &__action
    position: absolute
    top: 1rem
    right: 1rem

  &__secondary_action
    position: absolute
    top: 1rem
    left: 1rem
