.brand-nav
  height: $brand-nav-height
  background-color: rgba($teal, 0.3)
  --bs-navbar-color: #{rgba($primary, 0.8)}
  --bs-navbar-hover-color: #{$primary}
  backdrop-filter: blur(10px)

.brand-logo
  position: absolute
  bottom: 0
  left: 50%
  transform: translate(-50%, 50%)
  width: 84px
