//mixins
@import "styles/mixins/fontface"

//fonts
// @include fontFace('rockwell', '/assets/fonts/rockwell')
// @include fontFace('jost', '/assets/fonts/jost-v9-latin-regular')
@include fontFace('cera-pro', '/assets/fonts/Cera-Pro-Regular')
@include fontFace('Font Awesome 6 Pro', '/assets/fonts/webfonts_v6/fa-regular-400')

// theme
@import "../node_modules/bootstrap/scss/functions"
@import "styles/bootstrap/variables"
@import "styles/bootstrap/variables-dark"
@import "styles/variables"
@import "styles/animations"

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap"
@import "styles/bootstrap/overrides"
@import "styles/fontawesome_v6.min.css"

// stylesheets containers
@import "styles/containers/Root"
@import "styles/containers/Account"
@import "styles/containers/FourOFour"
@import "styles/containers/MenuPlanner"
@import "styles/containers/RecipeDetails"
@import "styles/containers/DishPlanner"
@import "styles/containers/DishPreview"
@import "~video-react/dist/video-react.css"

//stylesheets components
@import "components/FieldInput/FieldInput"
@import "components/FileInput/FileInput"
@import "components/Login/Login"
@import "components/MainMenu/MainMenu"
@import "components/MainNavbar/MainNavbar"
@import "components/Footer/Footer"
@import "components/OfflineBar/OfflineBar"
@import "components/PasswordRecovery/PasswordRecovery"
@import "components/PasswordReset/PasswordReset"
@import "components/Register/Register"
@import "components/Timeslot/Timeslot"
@import "components/Sidebar/Sidebar"
@import "components/Avatar/Avatar"
@import "components/Feedback/Feedback"
@import "components/ShoppingList/ShoppingList"

body
  margin: 0
  padding: 0

  font-family: 'cera-pro'
  font-size: 16px
  letter-spacing: 0.0125em
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: subpixel-antialiased

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

.btn-circle-float
  position: fixed
  bottom: 1rem
  right: 1rem
  z-index: 999
  i
    transform: translateY(15%)

.xsmall
  font-size: 70%

.cover-image
  background-color: $white
  width: 100%
  position: relative
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  background-origin: content-box
  background-color: $light

  display: flex
  justify-content: center
  align-items: center

  &:after
    content: ""
    padding-top: 66.6%

.text-clamp
  overflow: hidden
  max-height: 3.25rem
  position: relative

  &:after
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 2rem
    content: ""
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%)

:host,
:root
  --fa-style-family-classic: "Font Awesome 6 Pro"
  // --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"
  // --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"

.fa-light,
.fal
  font-weight: 300

.fa-regular,
.far
  font-weight: 400
