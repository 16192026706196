.main-menu
  display: flex
  flex-direction: column
  min-width: 180px
  max-width: 320px

  @media screen and ( max-width: 800px )
    min-width: 120px
    max-width: 200px

  .btn
    max-width: 100%
    padding-left: 1.5rem
    padding-right: 1.5rem
    margin-bottom: 1rem
    white-space: nowrap

    transition: padding-left 0.25s, padding-right 0.25s

  &.collapsed
    min-width: 60px
    max-width: 60px

    .btn
      padding-left: 1rem
      padding-right: 1rem
      justify-content: center
      span
        display: none
