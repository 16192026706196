.avatar
  display: inline-flex
  justify-content: center
  align-items: center
  width: 5rem
  height: 5rem
  min-width: 5rem
  min-height: 5rem
  mask-image: url("../../assets/images/og-shape-bg.svg")
  mask-size: contain
  background-color: $teal
  background-size: cover
  background-position: center

  font-size: 2.25rem
  text-transform: uppercase
  font-weight: bold

  &.avatar-sm
    width: 2.5rem
    height: 2.5rem
    min-width: 2.5rem
    min-height: 2.5rem

  &.avatar-lg
    width: 10rem
    height: 10rem
    min-width: 10rem
    min-height: 10rem

  &.avatar-xl
    width: 15rem
    height: 15rem
    min-width: 15rem
    min-height: 15rem
