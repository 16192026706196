.root
  min-height: 100vh
  position: relative
  padding-bottom: 5rem
  background-color: #FBFBFB
  background-image: url("../../assets/images/og-background.png")
  background-repeat: no-repeat
  background-position: center center
  background-size: cover

.content
  padding-top: $brand-nav-height

.video-react
  .video-react-big-play-button
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    border: 0
    background-color: $primary

.spaced-content
  min-width: 480px
  @include media-breakpoint-down(sm)
    min-width: 320px
