.expandable-list
  padding-top: 2rem
  transition: max-height 250ms ease-out

  &.expandable-list-collapsed
    max-height: 400px
    overflow: hidden
    position: relative
    &:after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 25%
      background: linear-gradient(0deg, rgba($white, 1) 10%, rgba($white, 0) 100%)

    .expandable-toggle
      bottom: 2rem

  .expandable-toggle
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: -1rem
    z-index: 1

    transition: bottom 250ms ease-out

.filer-dropdown
  max-height: 300px
  overflow-y: scroll

.list-table
  th
    cursor: pointer
